.applayout {
    padding: 1.5rem
}

.container {
    max-width: var(--layout-max-width);
    margin: 0 auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    -webkit-transition: max-width var(--layout-width-animation-time) ease-in-out;
    -moz-transition: max-width var(--layout-width-animation-time) ease-in-out;
    -o-transition: max-width var(--layout-width-animation-time) ease-in-out;
    transition: max-width var(--layout-width-animation-time) ease-in-out;
}

@media (min-width: 576px) {
    .container {
        max-width: var(--layout-width-sm);
    }
}


@media (min-width: 768px) {
    .container {
        max-width: var(--layout-width-md);
    }
}

@media (max-width: 768px) {
    .container {
        padding-right: 0;
        padding-left: 0;
    }    
}


@media (min-width: 992px) {
    .container {
        max-width: var(--layout-width-lg);
    }
}


@media (min-width: 1200px) {
    .container {
        max-width: var(--layout-width-xl);
    }
}


.wideContainer {
    max-width: 100%;
}