/* This is the gradient that's going to be used our navbar */
.navBar {
  background: var(--navbar-gradient);
  box-shadow: var(--navbar-box-shadow);
}

.item {
  font-weight: 500;
  color: #ffffff;
  font-size: 15px;
  padding: 0 0.8rem;
  position: relative;

  align-self: stretch;
  display: flex;
  align-content: center;
  align-items: center;
}

.item:hover {
  color: #e4e4e4 !important;
}


@media (min-width: 640px) {
  .item {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}


@media (max-width: 639px) {
  .activeItem:before {
    background: none !important;
  }

  .leaf,
  .leafRight {
    display: none;
  }

  .leafLeftOne,
  .leafLeftTwo {
    display: block;
  }

  .leafRightOne,
  .leafRightTwo {
    display: block;
  }

  .leafLeftTwo {
    height: 40px !important;
    left: 50px !important;
  }

  .leafRightOne {
    height: 30px !important;
  }

  .leafRightTwo {
    height: 40px !important;
  }

  .navbarLogo {
    position: relative;
    left: calc(50% - 60px) !important;
  }


}

@media (max-width: 1170px) {

  .item {
    font-size: 12px;

  }
}

@media (max-width: 1080px) {

  .item {
    font-size: 10px;

  }

  .navBarDiv {
    padding-left: 0px !important;
    padding-right: 0px !important;

  }

  .navBarDiv a {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.activeItem:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--navbar-active-item-bg);
  mix-blend-mode: soft-light;
}

.logo {
  height: 60px;
  padding: 5px;
}


.leaf {
  mix-blend-mode: soft-light;
  position: absolute;
  left: 0;
  bottom: 0;
}

.leafRight {
  mix-blend-mode: soft-light;
  position: absolute;
  right: 0;
  bottom: 0;
}

.leafLeftOne {
  height: 30px;
  width: auto;
  left: 25px;
}

.leafLeftTwo {
  left: 215px;
  height: 50px;
  width: auto;
}

.leafLeftThree {
  left: 265px;
  height: 30px;
  width: auto;
}

.leafRightOne {
  height: 30px;
  width: auto;
}

.leafRightTwo {
  height: 24px;
  width: auto;
  right: 50px;
}

.leafRightThree {
  height: 50px;
  width: auto;
  right: 275px;
}

.leafRightFour {
  height: 30px;
  width: auto;
  right: 325px;
}

.logo {
  position: relative;
  left: 20px;
  top: 0px;
}

@media (max-width: 768px) {
  .logo {
    display: flex;
    /* border: solid purple 2px; */
    align-items: center;
    text-align: center;
    justify-content: center;
    width: auto;
    width: 100%;
    /* display: none; */
  }
}


