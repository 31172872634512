.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

.mapContainer {
    border-radius: 25px;
    overflow: hidden;
}


.button {
    background-color: var(--light-green);  
    color: #FFF;
    text-align: center;
    ;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase; 
    width: 100%;
    padding-top: 13px;
    padding-bottom: 11px;
}

.error {
    background-color: red;
}

.button:disabled {
   filter: grayscale(1)
}

@media (max-width: 768px) {
    .container {
        padding: 0 30px
    }
}