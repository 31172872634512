.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

.container {
    padding-top: 5em;
}

@media (max-width: 768px) {
    .container {
        padding: 0 30px
    }
}