.container {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    cursor: pointer;
    transition: 0.4s all;
    background-image: linear-gradient(
      to right bottom,
      rgb(255, 255, 255),
      rgb(187, 247, 208, 0.35)
    );
}

.subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
}

.subContainerTimes {
    width: 100%;
    padding: 0px 12px;
    margin-top: 8px;
    text-align: center;

}

.subContainerStats {
    padding: 8px 12px;
    margin-top: 8px;
    margin-bottom: 8px;
}


.image {
    width: 100%;
    height: auto;
    padding: 8px;
    /* width: 285px;
    height: 171px; */
    aspect-ratio: 1.667;
}

.title {
    color: #000;
    text-align: center;
    ;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.button {
    background-color: var(--light-green);  
    color: #FFF;
    text-align: center;
    ;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase; 
    width: 100%;
    padding-top: 13px;
    padding-bottom: 11px;
}

.button:hover {
    background-color: #3e923c;  

}

.image {
    border-radius: 15px;

}

.link {
    width: 100%;
}

.container:hover {
    box-shadow: 0 5px 20px 0 #29b529cf;
}


.Syncing {
    /* border: 2px rgb(65, 65, 204) solid */
}

.Syncing:hover {
    box-shadow: 0 5px 20px 0 blue;
}

.Syncing .button {
    background-color: rgb(79, 79, 255);
}

.Syncing .button:hover {
    background-color: rgb(65, 65, 204);

}

.Running {
    /* border: 2px rgba(165, 165, 11, 0.489) solid */
}

.Running:hover {
    box-shadow: 0 5px 20px 0 #a0b204d6;

}

.Running .button {
    background-color: rgb(200, 200, 11);
}

.Running .button:hover {
    background-color: rgb(158, 158, 9);
}

/* .activeItem {
    border: 2px yellow dashed
}

.activeButton {
    background-color: yellow;
}

.activeButton:hover {
    background-color: yellow;
}

.activeItem:hover {
    box-shadow: 0 5px 20px 0 yellow;
} */