.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

.mapContainer {
    border-radius: 25px;
    overflow: hidden;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    justify-content: center;
}

.device {
    text-align: center
}


.title {
    margin-bottom: 8px;
}

.radial:before {
    border: none !important;
}

@media (max-width: 768px) {
    .container {
        padding: 0 30px
    }

    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }

}


@media (max-width: 400px) {
   
    

    .gridContainer {
        grid-template-columns: repeat(1, 1fr);
    }

}