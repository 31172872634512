.container {
}

@media (max-width: 520px) {
    .gridContainer {
      grid-template-columns: repeat(1, 1fr) !important;
    }
    .tractorTitle {
        font-size: 60px;
        line-height: 52px;
    }

    .chartContainer {
        padding: 0 10px !important; 
    }
}


@media (max-width: 450px) {
    .tractorTitle {
        font-size: 38px !important;
        line-height: 44px !important;
        /* word-spacing:9999px; */
        white-space: pre-line;
    }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.bgContainer {
  padding: 26px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  top: -8px;
  padding-top: 34px;
}

.paginateContainer {
  margin-top: 28px;
  text-align: center;
}

.tractorTitle {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 60px;
  font-size: 70px;
  font-weight: bold;
  color: #031832;
  line-height: 60px;
  /* font-family: roboto; */
}

.chartContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding-bottom: 20px;
  position: relative;
  z-index: 10;
  padding: 0 40px;
  transform: scaleX(1.02);
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 20px;
}

.addTractorIcon {
  background-color: #f8fffa;
  left: 10px;
  top: 25px;
  z-index: 4;
}

.chartContents {
  height: 300px;
  transform: scale(1.03);
  margin-bottom: -4px;
  padding-bottom: 10px;
}


@media (max-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 992px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .container {
      padding: 0 30px
  }
}



.totalContainer {
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  grid-column-gap: 38px;
  grid-row-gap: 22px;
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .totalContainer {
    grid-template-columns: 1fr;
  }
}

.listContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 38px;
  grid-row-gap: 22px;
}

.infoCardLink {
  text-decoration: none;
  color: inherit;
  display: grid;
}




.imgContainer {
  position: relative;
}

.img {
  position: relative;
  width: 70%;
  height: auto;
  margin: 0 auto;
}

.imgContainer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  transform: translate(-50%);
  background-color: #e5d5ff;
  border-radius: 50%;
}




@media (max-width: 1006px) {

  .img {
    width: 100%;
  }

  .imgContainer:before {
    width: 65%;
  }
}


.subContainer {
  gap: 40px;
  padding-top: 8em;
}

.startRun button {
  background-color: #031832;
  color: #FFF;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  padding: 12px 20px;
  border-radius: 6px;
  margin: 8px;
  transition-duration: 0.2s;
}

.startRun button:hover {
  background-color: #020e1c;
}

.navCardContainer:hover {
  box-shadow: 0 5px 20px 0 rgb(149, 117, 199) !important;
}