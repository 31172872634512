.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

.mapContainer {
    border-radius: 25px;
    overflow: hidden;
}


.button {
    background-color: var(--light-green);  
    color: #FFF;
    text-align: center;
    ;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase; 
    width: 100%;
    padding-top: 13px;
    padding-bottom: 11px;
}

.error {
    background-color: red;
}

.button:disabled {
   filter: grayscale(1)
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 26px;
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.15);
}


@media (max-width: 768px) {
    .gridContainer {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 768px) {
    .container {
        padding: 0 30px
    }
}