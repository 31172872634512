.container {
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 26px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.05);
}

.chartContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding-bottom: 20px;
  position: relative;
  z-index: 999;
}

.chartContents {
  height: 300px;
}

.titleClass {
  white-space: normal;
}

@media (max-width: 520px) {
  .listContainer {
    grid-template-columns: repeat(1, 1fr) !important;
  }


  .searchInputMobile {
    grid-column: span 1 !important
  }
  
  .materialInputFix {
    grid-column: span 1 !important
  }

  .titleClass {
    white-space: nowrap;
  }

  .sticky {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 576px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.totalContainer {
  display: grid;
  grid-template-columns: 0.9fr auto;
  grid-column-gap: 38px;
  grid-row-gap: 22px;
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
}

@media (max-width: 1006px) {
  .totalContainer {
    grid-template-columns: 1fr;
  } 

  .sticky {
    position: relative !important;
    top: unset !important;
  }
}

@media (max-width: 768px) {
  .totalContainer {
    grid-template-columns: 1fr;
  }

  .sticky {
    position: relative !important;
    top: unset !important;
  }
}

.listContainer {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 38px;
  grid-row-gap: 22px;
  grid-template-rows: min-content
}

.sticky {
  position: sticky;
  top: 20px;
}

.addTractorIcon {
  /* border: solid red 3px !important; */
}

@media (max-width: 768px) {
  .container {
      padding: 0 30px
  }
}



.paginateContainer {
  margin-top: 28px;
  text-align: center;
}

.searchInput {
  width: 50%;
  margin: 0 auto;
  padding: 10px;
}

.searchInputMobile {
  width: 50%;
  margin: 0 auto;
  padding: 10px;
  grid-column: span 2
}

.materialInputFix {
  grid-column: span 2
}

.linkContainer {
  height: fit-content !important;
}