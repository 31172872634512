.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: px;
  grid-row-gap: 24px;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.imgContainer {
  position: relative;
}

.img {
  position: relative;
  width: 70%;
  height: auto;
  margin: 0 auto;
}

.imgContainer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  transform: translate(-50%);
  background-color: #d4ffd2;
  border-radius: 50%;
}

@media (max-width: 1006px) {
  .container {
    grid-template-columns: 1fr 1.4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 12px;
    padding: 18px;
    position: relative;
  }

  .img {
    width: 100%;
  }

  .imgContainer:before {
    width: 65%;
  }

  .infoContainer {
    padding: 12px;
  }
}

.text {
  color: #093060;
  ;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.textSecondary {
  color: #093060;
  ;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
}
