.container {
    border-radius: 15px !important;
    background: #fff;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    justify-content: space-between;
    height: 100%;
    transition-duration: 0.2s;
    transition: 0.4s all;
  }

  .container:active:hover, .container:active:focus {
    animation: button-pop 0s ease-out;
    transform: scale(0.95, 0.97);
  }
  
  @keyframes button-pop {
    0% {
      transform: scale(0.95, 0.98);
    }
  
    40% {
      transform: scale(1.02);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  .gradient {
    background-image: linear-gradient(
      to right bottom,
      rgb(255, 255, 255),
      rgb(187, 247, 208, 0.35)
    );
  }
  
  .container:hover {
    background: #ffffff;
    box-shadow: 0 5px 20px 0 #29b529cf;
    transition: 0.4s all;
  }
  
  .subContainer {
    padding: 23px 23px 0 23px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .imgContainer {
    height: 84px;
    width: 84px;
    border-radius: 50%;
    background-color: #f0ffef;
    margin-bottom: 30px;
    transition: 0.4s all;
    display: flex;
    justify-content: center;
  }
  
  .imgContainer:hover {
    background-color: #ddf6dc;
  }
  
  .image {
    width: 100%;
    height: auto;
    padding: 8px;
  }
  
  .title {
    margin-bottom: 34px;
    color: #2f2f2f;
    text-align: center;
    ;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    white-space: break-spaces !important;
    max-width: 170px;
  }
  
  .button {
    background-color: var(--deep-blue);
    color: #fff;
    text-align: center;
    ;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 11px;
  }
  
  .button:hover {
    background-color: #010d1c;
  }
  
  .disabled {
    filter: grayscale(1);
    pointer-events: none;
  }
  
  .linkContainerSingle {
    height: fit-content !important;
  }

  .linkContainer {
    height: 100%
  }