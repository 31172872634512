.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

@media (max-width: 768px) {
    .container {
        padding: 0 30px
    }
}

.mapContainer {
    border-radius: 25px;
    overflow: contain;
}

.titleSpecialClassName {
margin-top: -10px;
margin-bottom: 20px;
  }

.invisible {
    opacity: 0;
    pointer-events: none;
}

.statSubtitle {
    white-space: nowrap !important
}

.statCardContainer {
    grid-template-columns: repeat(2, 1fr) !important; 
}

.statCardContainerTwo {
    grid-template-columns: repeat(1, 1fr) !important; 
}

.statCardContainerThree {
    grid-template-columns: repeat(3, 1fr) !important; 
}

.responsiveStatCard {
    display: block;
}

.break {
    word-spacing:9999px;
    white-space: pre-line;
}

@media (max-width: 1023px)

{


    .titleSpecialClassName {
        margin-top: unset;
        margin-bottom: 11px;
        /* margin-bottom: unset; */
    }

    .invisible {
        display: none;
    }

    .title {
        text-align: center;
    }

    .responsiveStatCard {
        display: contents;
    }

}


@media (max-width: 376px) {
    .titleSpecialClassName {
      margin-top: -8px;
      margin-bottom: 0px ;
    }
  }

.hiddenItem {
    display: none
}

@media (max-width: 768px) {
    .container {
        padding: 0 30px
    }

    .hiddenItem {
        display: block;
        visibility: hidden;
    }

}

@media (max-width: 992px) {
    .customGrid {
        grid-column: unset;
        display: block;
        grid-column-start: 1;
        grid-column-end: 12;
    }

}

.textArea {
    padding: 0
}

.gridTwoFifty {
    grid-template-columns: 50% 50% !important;
}

.wordWrap {
    overflow-wrap: break-word;
}




.smallerWordWrap {
    overflow-wrap: break-word;
    font-size: 24px;
}
.smallPadding {
    padding-left: 12px;
    padding-right: 12px;
}



@media (max-width: 1200px) {
    .gridContainer {
    grid-template-columns: 1fr;
    
    & aside {
     order: -1
    }
    }
    }