.container {

}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-rows: repeat(5, 1fr); */
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 26px;
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.15);
}

@media (max-width: 520px) {
    .gridContainer {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }


@media (min-width: 576px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}


@media (min-width: 768px) {
    .gridContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}


@media (min-width: 992px) {
    .gridContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}


@media (min-width: 1200px) {
    .gridContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}

.aerial {
white-space: break-spaces;
}

@media (max-width: 768px) {
    .container {
        padding: 0 30px
    }
}