.field {
  width: 100%;
  height: 56px;
  border-radius: 18px;
  position: relative;
  background-color: rgba(193, 223, 192, 0.336) !important;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
}

.field:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}

.field.active {
  background-color: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
}

.field.active input {
  padding: 24px 16px 8px 16px;
}

.field.active input + label {
  top: 4px;
  opacity: 1;
  color: rgba(52, 139, 66, 0.9);
}

.field.locked {
  pointer-events: none;
}

.field input {
  width: 100%;
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
}

.field input::-webkit-input-placeholder {
  color: rgba(52, 139, 66, 0.4);
}
.field input::-moz-placeholder {
  color: rgba(52, 139, 66, 0.4);
}
.field input:-ms-input-placeholder {
  color: rgba(52, 139, 66, 0.4);
}
.field input:-moz-placeholder {
  color: rgba(52, 139, 66, 0.4);
}

.field input + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s all ease-in-out;
}

.field label {
  color: rgba(52, 139, 66, 0.5) !important;

}

.field input::placeholder {
  color: rgba(52, 139, 66, 0.4);
}

.field input:focus::placeholder {
  color: transparent;
}

.field input + label.error {
  color: #ec392f;
}

.field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}
  