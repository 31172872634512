.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    padding: 0 40px;
}

.mapContainer {
    border-radius: 25px;
    overflow: hidden;
}


.button {
    background-color: var(--light-green);  
    color: #FFF;
    text-align: center;
    ;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase; 
    width: 100%;
    padding-top: 13px;
    padding-bottom: 11px;
}

.error {
    background-color: red;
}

.button:disabled {
   filter: grayscale(1)
}

.configbutton {
    display: inline-block;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    padding: 0.5rem 1.25rem;
    font-size: 0.8rem;
    font-weight: 500;
    color: #fff;
    background-color: #0f6b94;
    border-color: #0f6b94;
    /* transition: background-color 0.3s, border-color 0.3s, color 0.3s; */
    outline: none;
    margin-left: 0.25rem;
    margin-bottom: 0.75rem;
    position: relative;
    z-index: 10;
  }
  
  .configbutton:hover {
    background-color: #085c7d;
    border-color: #085c7d;
  }
  
  .configbutton:active {
    color: #fff;
    background-color: #04374b;
    border-color: #04374b;
  }
  

@media (max-width: 420px) {
    .container {
        padding: 0;
    }
  }

  @media (max-width: 768px) {
    .container {
        padding: 0 30px
    }
}