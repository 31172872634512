.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: top;
    align-items: top;
    padding: 25px 40px;
    margin-top: 5px;
    padding-bottom: 40rem;
}