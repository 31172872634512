.text {
  display: block;
  white-space: nowrap;
  cursor: pointer;
}

.testclass {
  transform: translate3d(-60px, 62px, 0px) !important;
}

.loginDropdown {
  /* border: solid red 2px; */
}


@media (max-width: 639px) {
  .text {
    display: block;
    /* border: solid red 2px; */
    display: flex;
    justify-content: end;
    align-items: end;
  }
}
