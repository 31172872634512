.MuiDialogContent-root {
    padding: 0;
    margin: 0;
}

.title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    align-items: stretch;
    padding: 50px 50px 80px 50px;
    width: fit-content;
    margin: 0 auto;

    padding: 2em 50px;
}

.mapContainer {
    border-radius: 25px;
    overflow: hidden;
}

.progressContainer {
    display: flex;
}


.button {
    background-color: var(--light-green);  
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase; 
    padding: 12px;
    border-radius: 6px;
    margin: 15px 8px;
    transition-duration: 0.2s;
}

.button:hover {
  background-color: rgb(5, 158, 5)

}

.button:active:hover,
.button:active:focus {
  animation: button-pop 0s ease-out;
  transform: scale(0.95, 0.97);
  background-color: rgb(1, 124, 1)
}

.button:disabled:hover,
.button:disabled:focus {
  animation: none;
  transform: none;
}

@keyframes button-pop {
  0% {
    transform: scale(0.95, 0.98);
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.error {
    background-color: red;
}

.button:disabled {
   filter: grayscale(1)
}

.addTractorRunContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

}

.addTractorRunSelectContainer {
  display: flex;
  flex-direction: column;
  width: 100%
}


.addTractorRunDeveloperContainer {
  display: flex;
  flex-direction: column;
  border: solid #ebf0ee 1px;
  border-radius: 15px;
  background-color: #ebf0ee;
  padding: 15px;
  margin-top: 40px;
  width: 100%
}

.addTractorRunDeveloperContainer input {
  border: solid #bdc1c0 1px;
  background-color: #f8fcfa;
  padding: 5px;
  border-radius: 5px;



}

.startStopButtons {
}

.cancel_button {
    background-color: #b00f0f !important;
}

.cancel_button:hover {
    background-color: #ff0f0f !important;
}

.wait_button {
    background-color: yellow !important;
}

.wait_button:hover {
    background-color: #ff0f0f !important;
}

@media (max-width: 768px) {
    .container {
        padding: 2em 30px
    }
}

@media (max-width: 900px) {
  .addTractorRunContainer {
     flex-direction: column;
  }

  .addTractorRunContainer select {
    margin: 0 !important;
  }
}